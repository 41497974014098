import { useQuery } from 'react-query';
import SimulationAPI from '../../apis/SimulationAPI';
import strToTitleCase from '../../utils/strToTitleCase';

const gridHealthDataTemplate = { cases: [], lines: [], nodes: [] };

const API_FETCH_INTERVAL_DELAY = 2000;

// UTILS:

const { assign } = Object;

// /** midPoint - Determine the position of a point when the line is equally divided into segments
//  * @see https://www.dummies.com/article/academics-the-arts/math/trigonometry/how-to-divide-a-line-segment-into-multiple-parts-149453
//  * @param {Number} start - origin point
//  * @param {Number} end - destination point
//  * @param {Number} point - which point number of the equal segments
//  * @param {Number} segments - number of equal length segments between start and end
//  * @returns {Number} - position of point when start and end equaly divided into segments
//  */
// const midPoint = (start, end, point, segments) => (end - start) * (point / segments) + start;

// /** lineDistance - Find the distance between two coordinates
//  * @see https://www.dummies.com/article/academics-the-arts/math/geometry/understanding-distance-formula-229978
//  * @param {Number} x1 - point 1 X value
//  * @param {Number} y1 - point 1 Y value
//  * @param {Number} x2 - point 2 X value
//  * @param {Number} y2 - point 2 Y value
//  * @returns {Number} - length of the line segment
//  */
// const lineDistance = (x1, y1, x2, y2) => Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

/** getWaypoints - Convert point data into waypoints for TripsLayer
 * @param {Object} record Input data
//  * @param {Number} timeStep - only used for experimental fns (see: midPoint | lineDistance)
//  * @param {Number} startTimestamp
//  * @param {Number} stopTimestamp
 * @returns {Object} Expanded record data
*/
const getWaypoints = (record, timeStep, startTimestamp, stopTimestamp) =>
  // const distance = lineDistance(
  //   record.from_lat_long[0],
  //   record.from_lat_long[1],
  //   record.to_lat_long[0],
  //   record.to_lat_long[1],
  // );
  // const segments = Math.floor(distance);
  // const quotient = distance / segments;
  // const timeSegment = timeStep / quotient;
  // const waypoints = [];
  // for (let i = 0; i < segments; i += quotient) {
  //   waypoints.push({
  //     coordinates: [
  //       midPoint(record.from_lat_long[0], record.to_lat_long[0], i, segments),
  //       midPoint(record.from_lat_long[1], record.to_lat_long[1], i, segments),
  //     ],
  //     timestamp: timeSegment * i + record.timestamp,
  //   });
  //   console.log(i, segments);
  // }
  // // console.log(waypoints);
  // return { waypoints, ...record };
  ({
    waypoints: [
      { coordinates: record.from_lat_long, timestamp: stopTimestamp },
      { coordinates: record.to_lat_long, timestamp: startTimestamp },
    ],
    ...record,
  });

/** transformGridHealthData - Separates types and get unique cases
 * @param {Object} data
 * @returns {Object}
 */
const transformGridHealthData = ({
  simulation: {
    result,
    sim_start_timestamp: startTimestamp,
    sim_stop_timestamp: stopTimestamp,
    sim_options: { time_step: timeStep } = {},
  },
}) =>
  result.reduce(
    (collection, record) => {
      if ([record.bus_type, record.line_type].includes('slack')) {
        /** Filter `slack` bus/line */
        collection.slack.push(record);
      } else if (record.object === 'sgen') {
        /** Filter `sgen` */
        collection.sgen.push(record);
      } else if (record.object === 'line') {
        /** Filter `line` */
        record = getWaypoints(record, timeStep, startTimestamp, stopTimestamp);
        collection.lines.push(record);
      } else if (record.object === 'bus') {
        /** Filter `bus` (nodes) */
        collection.nodes.push(record);
      }
      // Unique cases
      collection.cases = [...new Set([...collection.cases, record.case])];
      return collection;
    },
    { cases: [], lines: [], nodes: [], sgen: [], slack: [] },
  );

/** transformInfoPanelData - Isolates Event details data
 * @param {Object} data
 * @returns {Object}
 */
function transformInfoPanelData({ temperatureChange, lostGenerators, naturalGas, solar, wind }) {
  return { temperatureChange, lostGenerators, naturalGas, solar, wind };
}

/** transformSimulationOptionsData - Updates viewState latitude and zoom based on device viewport
 * @returns {Object}
 */
const transformSimulationOptionsData = ({ longitude, latitude, zoom, pitch, bearing }) => {
  const innerHeight = window.innerHeight / window.devicePixelRatio;
  const gtH960 = window.innerHeight > 960;
  const aspectRatio = window.outerHeight / window.outerWidth;
  return {
    bearing,
    pitch,
    longitude: longitude - (!gtH960 && aspectRatio > 1.7 ? 0 : -1.5),
    latitude: latitude - (!gtH960 && aspectRatio > 1.7 ? 1 : -3),
    zoom: (innerHeight * 0.99) ** 0.23 + (gtH960 ? 0 : 0.6),
  };
};

/** transformTimestampsData - Calculates Timestamps data
 * @param {Object} data
 * @returns {Object}
 */
const transformTimestampsData = ({
  simulation: { result, sim_options: { time_step: timeStep } = {} },
}) => {
  const timestamps = [...new Set(result.map((record) => record.timestamp))];
  const steps = timestamps.length;
  const minTimestamp = timestamps[0];
  const maxTimestamp = timestamps[steps - 1];

  return { steps, minTimestamp, maxTimestamp, timestamps, timeStep };
};

/** intervalFetch - Request API simulation until simulation complete
 * @param {Object} scenarioOptions - { scenario, setSimulationStep, setSimulationSteps }
 * @param {Object} options - { {?string}simId, {?Object}payload, {Number}intervalId }
 * @returns {Object}
 */
const intervalFetch = async (scenarioOptions, options) => {
  try {
    const response = await SimulationAPI.getSimulation(scenarioOptions.scenario, options);
    // scenarioOptions.setSimulationStep(response.simulation.step);
    // scenarioOptions.setSimulationSteps(response.simulation.total_steps);
    if (response.simulation.status === 'complete') {
      options.stopInterval();
      options.resolve(response);
    }
  } catch (error) {
    options.reject({ error });
  }
};

/** getData - Fetch simulation from API on an interval until status:complete (see `intervalFetch`)
 * @param {Object} options
 * @returns {Promise}
 */
let stopInterval;
const getData = ({ queryKey }) => {
  const [_, scenarioOptions, payload = {}] = queryKey;
  if (typeof stopInterval === 'function') stopInterval();
  let intervalId;
  // scenarioOptions.setSimulationStep(-1);
  // scenarioOptions.setSimulationSteps(0);
  return new Promise((resolve, reject) => {
    (async (resolve, reject) => {
      const { scenario } = scenarioOptions;
      payload.name = `Generation simulation for ${strToTitleCase(scenario.replace(/_/g, ' '))}`;
      payload.description =
        // eslint-disable-next-line no-nested-ternary
        scenario.includes('Normal')
          ? 'Normal scenario data'
          // eslint-disable-next-line no-nested-ternary
          : scenario.includes('Abnormal')
          ? 'Abnormal weather scenario data'
          : scenario.includes('Arctic Event')
          ? 'Extreme weather scenario data'
          : '';
      const options = { payload, reject, resolve };
      const { sim_id: simId } = await SimulationAPI.getSimulation(scenario, options);
      assign(options, { reject, resolve, simId });
      stopInterval = options.stopInterval = clearInterval.bind(
        null,
        setInterval(intervalFetch, API_FETCH_INTERVAL_DELAY, scenarioOptions, options),
      );
    })(resolve, reject);
  });
};

// REACT-QUERY DATA CACHING:

/** useGridHealthQuery - Caches fetched [Simulate] API data
 * @param {Object} scenarioOptions - { scenario, setSimulationStep, setSimulationSteps }
 * @param {Object} options
 */
const useGridHealthQuery = (scenarioOptions, options) =>
  useQuery(['grid-health', scenarioOptions], getData, {
    initialData: {
      temperatureChange: 0,
      lostGenerators: 0,
      naturalGas: 0,
      solar: 0,
      wind: 0,
      simulation: { result: [] },
    },
    ...options,
  });

/** useSimulationOptionsQuery - Caches fetched [Simulate Options] API data */
const useSimulationOptionsQuery = () =>
  useQuery('simulation-options', SimulationAPI.getMapConfig, {
    select: transformSimulationOptionsData,
    initialData: {
      // Defaults:
      longitude: -97.5,
      latitude: 30,
      zoom: 5,
    },
  });

/** useSimulationTypesQuery - Caches fetched [Simulate Types] API data */
const useSimulationTypesQuery = () =>
  useQuery('simulation-types', SimulationAPI.getTypes, {
    initialData: [],
  });

// EXPORT:

/** useGridHealthData - Returns specialized and transformed data which was fetched from the API
 * @param {Object} scenarioOptions - { scenario, setSimulationStep, setSimulationSteps }
 * @returns {Object}
 */
export function useGridHealthData(scenarioOptions) {
  return {
    simulationOptionsData: useSimulationOptionsQuery(),
    simulationTypesData: useSimulationTypesQuery(),
    gridHealthData: useGridHealthQuery(scenarioOptions, { select: transformGridHealthData }),
    infoPanelData: useGridHealthQuery(scenarioOptions, { select: transformInfoPanelData }),
    timestampsData: useGridHealthQuery(scenarioOptions, { select: transformTimestampsData }),
  };
}
